<template>
  <div>
    <b-row v-if="version.length > 0">
      <b-col cols="12">
        <b-card class="my-card m-0">
          <b-table
            striped
            small
            id="myTables"
            bordered
            selectable
            selected-variant="primary"
            select-mode="single"
            responsive="sm"
            head-row-variant="secondary"
            :items="version"
            :fields="fields"
            @row-clicked="clicked"
            @row-dblclicked="editForm"
          >
            <template #cell(checkbox)="row">
              <b-form-checkbox
                v-model="row.detailsShowing"
                plain
                class="vs-checkbox-con"
                @change="row.toggleDetails"
              >
              </b-form-checkbox>
            </template>
            <template #cell(status)="data">
              {{ data.item.status ? "Активен" : "Не активен" }}
            </template>
            <template #cell(created_at)="data">
              {{ moment(data.item.created_at).format("DD.MM.YYYY HH:mm") }}
            </template>
            <template #cell(updated_at)="data">
              {{ moment(data.item.updated_at).format("DD.MM.YYYY HH:mm") }}
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { resizeable } from "@/utils/TableFunctions/resizeable";
import { cellSelect } from "@/utils/TableFunctions/cellSelect";
import moment from "moment";
export default {
  props: ["version"],
  data() {
    return {
      fields: [
        { key: "checkbox", label: "" },
        { key: "id", label: "ID", sortable: true },
        { key: "current_version", label: "Корректное версия", sortable: true },
        { key: "min_version", label: "Минимальное версия", sortable: true },
        { key: "stable_version", label: "Стабильная версия", sortable: true },
        { key: "platform", label: "Платформа", sortable: true },
        { key: "services", label: "Сервис", sortable: true },
        { key: "status", label: "Статус", sortable: true },
        { key: "created_at", label: "Создан", sortable: true },
        { key: "updated_at", label: "Обновлен", sortable: true },
      ],
    };
  },
  mounted() {
    resizeable();
  },
  methods: {
    moment,
    clicked(item, index, event) {
      this.$store.state.filterPlus.tableIndex = index;
      const clickedElement = event.target;
      const clickedCell = clickedElement.closest("td");

      if (!clickedCell) {
        return;
      }

      const columnIndex = Array.from(clickedCell.parentNode.children).indexOf(
        clickedCell
      );

      if (columnIndex < 0 || columnIndex >= this.fields.length) {
        return;
      }

      const clickedField = this.fields[columnIndex];
      this.$store.state.filterPlus.keyFilter = clickedField.key;

      this.$store.state.filterPlus.filterPlus = event.target.innerHTML.trim();
      cellSelect();
    },
    editForm(item) {
      this.$emit("editForm", item);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/table.scss";
.my-card {
  padding: 0px;
}
.my-card {
  overflow: auto;
  height: calc(100vh - 92px);
}
@media (max-height: 700px) {
  .my-card {
    overflow: auto;
    height: 530px;
  }
}
</style>
